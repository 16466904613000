import Axios from '../axios-instance/axios.service';

const API_URL =
  process.env.NODE_ENV !== 'development' ? `${window.location.origin}/api/` : 'http://localhost:5000/api/';

class ProgramsService {
  async getPrograms(user) {
    const { data } = await Axios.post(`${API_URL}programs`, user);
    return data;
  }
  async getProgram(user, id) {
    let req = {
      ...user,
      repaymentId: id
    }
    const { data } = await Axios.post(`${API_URL}program`, req);
    return data;
  }
}

export default new ProgramsService();