<template>
  <div class="repayments-page">
    <div v-if="$system.user.isAuthenticated" class="container">
      <!--Page title-->
      <Title :title="title" />
      <!--Search-->
      <div class="row">
        <b-input-group size="sm" class="mb-3 col-12 col-lg-3 p-0">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Enter search text"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
        <div class="col-2 col-lg-3 p-0 mb-3">
          <b-form-group
            label="Per page"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-10 col-lg-1 offset-lg-5 mb-3">
          <div class="flex-end">
            <b-icon-arrow-counterclockwise
              @click="getRepayments"
              class="btn__refresh"
              v-b-tooltip.hover
              title="Refresh"
            />
          </div>
        </div>
      </div>
      <!--Programs-->
      <div v-if="!loading" class="row">
        <b-table
          class="table"
          hover
          striped
          :items="repayments"
          :fields="fieldsRepayments"
          :filter="filter"
          :filter-included-fields="filterOn"
          responsive
          :current-page="currentPage"
          :per-page="perPage"
        >
          <!--Program Router Link-->
          <template #cell(Name)="row">
            <div>
              <router-link
                :to="'/programs/' + row.item.Id"
                v-b-tooltip.hover
                title="Show program"
                class="active-link-table"
              >
                {{ row.item.Name }}
              </router-link>
            </div>
          </template>
          <!--Account Router Link-->
          <template #cell(Account)="row">
            <div>
              <router-link
                :to="'/accounts/' + row.item.Account.Id"
                v-b-tooltip.hover
                title="Account information"
                class="active-link-table"
              >
                {{ row.item.Account.Name }}
              </router-link>
            </div>
          </template>
        </b-table>

        <div v-if="!repayments.length" class="col-12 flex-center">
          <p class="text-center p-5">No records found.</p>
        </div>
        <!--Table Pagination-->
        <div class="col-12 flex-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
      </div>
      <div v-else class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
    <div v-else class="container">
      <div class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import './ProgramsPage.scss'
import ProgramsService from '@/services/programs/programs.service'
import Title from '@/components/main-layout/title/Title.vue'
export default {
  name: 'ProgramsPage',
  components: {
    Title,
  },

  metaInfo: {
    title: 'Programs',
    titleTemplate: '%s | Merchant Portal | Nexi ',
  },
  data() {
    return {
      loading: false,
      title: 'Programs',
      repayments: [],
      fieldsRepayments: [
        {
          key: 'Name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'Type',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'PercentPaid',
          label: 'Percent Paid',
          sortable: true,
        },
        {
          key: 'StartDate',
          label: 'Start Date',
          sortable: false,
        },
        {
          key: 'Status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'FundingAmount',
          label: 'Funding Amount',
          sortable: false,
        },
        {
          key: 'PaybackAmount',
          label: 'Payback Amount',
          sortable: false,
        },
        {
          key: 'FactorRate',
          label: 'FR',
          sortable: true,
        },
        {
          key: 'ClearedPaid',
          label: 'Cleared Paid',
          sortable: false,
        },
        {
          key: 'PendingBalance',
          label: 'Pending Balance',
          sortable: false,
        },
        {
          key: 'ClearedBalance',
          label: 'Cleared Balance',
          sortable: false,
        },
        {
          key: 'CVE',
          label: 'CVE',
          sortable: true,
        },
        {
          key: 'Account',
          label: 'Account',
          sortable: false,
        },
      ],
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 40, 60, { value: 1000, text: 'Show all' }],
    }
  },
  computed: {
    auth() {
      return this.$system.user.isAuthenticated
    },
  },
  watch: {
    auth() {
      this.getRepayments()
    },
  },
  created() {
    if (this.$system.user.isAuthenticated) {
      this.getRepayments()
    } else this.$system.firstLoading()
  },
  methods: {
    async getRepayments() {
      try {
        this.loading = true
        const response = await ProgramsService.getPrograms(
          this.$system.user,
        )
        if (
          response &&
          response.status == 'success' &&
          response.body &&
          response.body.data
        ) {
          this.repayments = response.body.data
          this.totalRows = this.repayments.length
          this.loading = false
        } else {
          this.loading = false
        }
      } catch (err) {
        this.loading = false
      }
    },
  },
}
</script>
