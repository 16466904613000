import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'MainView',
    component: () => import('@/views/MainView.vue'),
    children: [
      {
        path: '',
        name: 'main.home',
        component: () => import('@/components/pages/home-page/HomePage.vue'),
      },
      {
        path: '/registration',
        name: 'main.registration',
        component: () => import('@/components/pages/register-page/RegisterPage.vue'),
      },
      {
        path: '/login',
        name: 'main.login',
        component: () => import('@/components/pages/login-page/LoginPage.vue'),
      },
      {
        path: '/loginviewer',
        name: 'main.loginviewer',
        component: () => import('@/components/pages/login-admin-page/LoginAdminPage.vue'),
      },
      {
        path: '/profile',
        name: 'main.profile',
        component: () => import('@/components/pages/user-page/UserPage.vue'),
      },
      {
        path: '/accounts',
        name: 'main.accounts',
        component: () => import('@/components/pages/accounts-page/AccountsPage.vue'),
      },
      {
        path: '/accounts/:id',
        name: 'main.account',
        component: () => import('@/components/pages/account-page/AccountPage.vue'),
      },
      {
        path: '/programs',
        name: 'main.programs',
        component: () => import('@/components/pages/programs-page/ProgramsPage.vue'),
      },
      {
        path: '/programs/:id',
        name: 'main.programs',
        component: () => import('@/components/pages/program-page/ProgramPage.vue'),
      },
      {
        path: '/*',
        name: 'main.error',
        component: () => import('@/components/pages/404-page/404.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

export default router;
